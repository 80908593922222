<template>
	<div style="margin-left: 20px;">
		<div class="icon1">
			<img src="../static/Rectangle543(7).png" class="px24" alt="">
			<div>Ⅹ+级</div>
		</div>
		<div class="icon1">
		<img src="../static/Rectangle543(6).png" class="px24" alt="">
			<div>Ⅸ级</div>
		</div>
		<div class="icon1">
			<img src="../static/Rectangle543(5).png" class="px24" alt="">
			<div>Ⅷ级</div>
		</div>
		<div class="icon1">
			<img src="../static/Rectangle543(4).png" class="px24" alt="">
			<div>Ⅶ级</div>
		</div>
		<div class="icon1">
			<img src="../static/Rectangle543(3).png" class="px24" alt="">
			<div>Ⅵ级</div>
		</div>
		<div class="icon1">
			<img src="../static/Rectangle543(2).png" class="px24" alt="">
			<div>Ⅴ级</div>
		</div>
		<div class="icon1">
			<img src="../static/Rectangle543(1).png" class="px24" alt="">
			<div>Ⅳ级</div>
		</div>
		<div class="icon1">
			<img src="../static/Rectangle543.png" class="px24" alt="">
			<div>Ⅱ-Ⅲ级</div>
		</div>
		<div class="icon1">
			<img src="../static/Frame9558.png" class="px24" alt="">
			<div>Ⅰ级</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				map: null,
				ck: 0,
				count: 0,
				lst: true
			};
		},
		mounted() {},
		destroyed() {},
		methods: {}
	};
</script>

<style scoped lang="scss">
	.icon1 {
		display: flex;
		align-items: center;
		width: 70px;
		justify-content: space-between;
		margin-bottom: 10px;

		.ic1 {
			background-color: rgba(222, 105, 64, 1);
			border-radius: 15px;
			margin-right: 10px;
		}
	}
	.px24{
		width: 24px;
		height: 22px;
	}
	.px12{
		width: 12px;
		height: 12px;
	}
	.px10{
		width: 10px;
		height: 10px;
	}
	.px7{
		width: 7px;
		height: 7px;
	}
	.px5{
		width: 5px;
		height: 5px;
	}
</style>
