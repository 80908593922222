<template>
	<div style="width: 100%; height: 100%; background-color: rgba(224, 225, 226, 1); ">
		<div class="hd" v-show="lst">
			<div style="display: flex; justify-content: space-between; width: 500px; cursor: pointer;">
				<div :class="ck == 0?'c2':'c1'" @click="Ckd(0)">地震危险性</div>
				<div :class="ck == 1?'c2':'c1'" @click="Ckd(1)">地震滑坡危险性</div>
				<div :class="ck == 2?'c2':'c1'" @click="Ckd(2)">交通网络地震风险</div>
				<!-- <div :class="ck == 3?'c2':'c1'" @click="Ckd(3)">断层数据</div> -->
			</div>
		</div>
		<div style="width: 1200px; height: 90%;  margin-left: 50%; transform: translateX(-50%); overflow-y: auto; padding: 10px 10px 10px 10px; background-color: #FFFFFF; margin-top: 10px; display: flex; flex-wrap: wrap; box-sizing: border-box; position: relative;"
			v-show="lst">

			<div v-for="item in Item_data" style="width: 1152px; height: 212px; background-color: rgba(245, 245, 245, 1); margin-bottom: 10px; margin-right: 10px;box-shadow: 0px 4px 12px rgba(72, 90, 126, 0.24);
			border-radius: 6px; overflow: hidden; display: flex; flex-wrap: wrap;" @click="opmap(item)">
							<img :src="item.img" alt="" style="width: 250px; height: 212px;">
							<div
								style="color: rgba(53, 53, 53, 1);font-size: 24px; font-weight: 500; margin-left: 10px;  overflow: hidden;">
								<div style="width: 250px; overflow: hidden;text-decoration:underline">{{item.name}}</div>
								<div style="color: #353535; font-size: 18px; margin-top: 20px; font-weight: 400;">
									<!-- <div>作者：Esri</div> -->
									<div>空间范围:{{item.kj}}</div>
								</div>
								<div style="color: #353535; font-size: 18px; font-weight: 400;">
									<!-- <div>作者：Esri</div> -->
									<div>更新日期:{{item.time}}</div>
								</div>
								<div style="color: #353535; font-size: 18px; font-weight: 400; width: 830px;word-break: break-all; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; margin-top: 10px;">
									<!-- <div>作者：Esri</div> -->
									<div>简介:{{item.txt}}</div>
								</div>
							</div>
						</div>
			<div v-if="ck == 2" style="font-size: 24px;">正在建设中。。。</div>
			<div v-if="Item_data.length == 0 && ck !== 2" style="font-size: 24px;">暂无数据。。。</div>
		</div>

		<div v-show="!lst">
			<div class="topbc" @click="bak">
				<img src="../static/Huge-icon.png" style="width: 24px; height: 24px;" alt="">
				{{item.name}}
			</div>
			<div class="lis">
				<div class="lft">
					<div class="hd1">
						介绍
					</div>
					<img :src="item.img" class="h1" alt="">
					<div class="h2">
						<div>{{item.name}}</div>
						<!-- <div style="margin: 10px 0;">作者：Esri</div> -->
						<div>更新日期:{{item.time}}</div>
						<div class="lin"></div>
						<div style="font-size: 24px;">描述</div>
						<div>
							{{item.txt}}
						</div>
					</div>
				</div>
				<div style="width: 1200px; height: 820px; overflow: hidden;">
					<div id="SceneView" style="width: 1200px; height: 820px; "></div>
				</div>
				<div class="lft">
					<div class="hd1">
						属性
					</div>
					<div class="h2" style="margin-top: 20px;">
						<div style="display: flex; align-items: center;" v-if="item.num == 0">
							类型
							<el-select @change="ChanPG" v-model="value" placeholder="请选择" style="margin-left: 20px;">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<!-- <div style="font-size: 24px;">地震幅度</div>
						<div style="font-size: 20px;">震级</div>
						<icon v-if="item.typ == 0"></icon>
						<div class="lin"></div>
						<div style="font-size: 24px;">震动强度</div>
						<div style="font-size: 20px;">强度值</div>
						<icon1 v-if="item.typ == 0"></icon1> -->
					</div>
				</div>
			</div>
		</div>



		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>该板块正在建设中。。。</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		loadModules
	} from "esri-loader";
	import icon from './icon.vue'
	import icon1 from './icon1.vue'
	import Data from './data.json'
	export default {
		components: {
			icon,
			icon1
		},
		data() {
			return {
				map: null,
				ck: 0,
				count: 0,
				lst: true,
				Dataarr: [],
				item: {},
				Item_data: [],
				dialogVisible: false,
				ckDz: 0,
				imgcs: '../static/4981675996141_.pic_thumb.jpg',
				options: [{
					value: 0,
					label: 'PGA(cm/s2)'
				}, {
					value: 1,
					label: 'PGV(cm/sec)'
				}, {
					value: 2,
					label: 'PGD(cm)'
				}],
				value: 0
			};
		},
		mounted() {
			// this.createView();
			this.Dataarr = Data
			this.Item_data = this.Dataarr.Pg_dz
			// console.log(this.Dataarr.Sj_Recent.ner[0].img);
		},
		destroyed() {
			if (this.map) {
				this.map.destroy()
			}
		},
		methods: {
			ChanPG(e) {
				console.log(e);
				this.map.destroy()
				if (e == 0) {
					this.createView(this.item.PGA);
				} else if (e == 1) {
					this.createView(this.item.PGV);
				} else {
					this.createView(this.item.PGD);
				}
			},
			ckDz1(id) {
				if (id == 0) {
					this.Item_data = this.Dataarr.Sj_Recent.ner
				} else {
					this.Item_data = this.Dataarr.Sj_Recent.lan
				}
				this.ckDz = id
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			bak() {
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				setTimeout(() => {
					loading.close();
				}, 500);
				this.lst = true
				this.map.destroy()
				// console.log(this.map);
			},
			opmap(item) {
				this.lst = false
				this.item = item
				console.log(item);
				if (item.num == 0) {
					this.createView(item.PGA);
				} else {
					this.createView(item.id);
				}
				// this.createView(item.id);
			},
			Ckd(id) {
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				setTimeout(() => {
					loading.close();
				}, 100);
				this.ck = id
				if (id == 0) {
					this.Item_data = this.Dataarr.Pg_dz
				} else if (id == 1) {
					this.Item_data = this.Dataarr.Pg_hp
				} else if (id == 2) {
					this.dialogVisible = true
					this.Item_data = []
				} else if (id == 3) {
					this.dialogVisible = true
					this.Item_data = []
				}

			},
			//创建视图
			createView(id) {
				let _this = this
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				//   let options = {
				//     url: "https://js.arcgis.com/4.15/",
				//     css: "https://js.arcgis.com/4.15/esri/themes/light/main.css"
				//   };

				//         const options = {
				//     url: 'https://js.arcgis.com/3.24/'
				// }
				const options = {
					// url: 'https://js.arcgis.com/3.24/',
					url: 'https://js.arcgis.com/3.24compact/',
					css: "https://js.arcgis.com/3.24/esri/css/esri.css"
				}
				//   loadModules([
				//     'esri/map',
				//     'esri/layers/ArcGISTiledMapServiceLayer',
				//     'esri/layers/ArcGISDynamicMapServiceLayer',
				//     'esri/symbols/SimpleMarkerSymbol',
				//     'esri/layers/GraphicsLayer',
				//     'esri/symbols/PictureMarkerSymbol',
				//     'esri/tasks/QueryTask',
				//     'esri/tasks/query',
				//     'esri/geometry/Point',
				//     'esri/graphic',
				//     'esri/SpatialReference',
				//     'esri/graphic',
				//   ],options).then(
				// "esri/views/SceneView", 
				loadModules(["esri/map", "esri/arcgis/utils", "dojo/domReady!"], options).then(
					([Map, SceneView]) => {
						var mapid = id
						var mapDeferred = esri.arcgis.utils.createMap(mapid, "SceneView", {
							mapOptions: {
								slider: true,
								nav: false,
								// center: [108.538570, 32.3599187],
								zoom: 9,
								logo: false
							}
						}).then(function(response) {
							_this.map = response.map;
							console.log(_this.map, '地图数据');
							setTimeout(() => {
								loading.close();
							}, 0);
						})
					}
				);
			}
		}
	};
</script>

<style scoped lang="scss">
	.bg1 {
		background: #F2F3F8;
	}

	.bg2 {
		background: #FFFFFF;
		color: rgba(22, 100, 255, 1);
		border: 1px solid #F2F3F8;
	}

	.topbc {
		width: 100%;
		height: 52px;
		line-height: 52px;
		font-size: 24px;
		background-color: rgba(255, 255, 255, 1);
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding-left: 20px;
		border-bottom: 1px solid #cccccc;
		cursor: pointer;
	}

	.lis {
		width: 100%;
		height: 80%;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		font-size: 16px;

		.lft {
			width: 360px;
			height: 820px;
			overflow: hidden;

			.hd1 {
				width: 100%;
				height: 52px;
				line-height: 52px;
				padding-left: 20px;
				box-sizing: border-box;
				background-color: #FFFFFF;
			}

			.h1 {
				width: 278px;
				height: 180px;
				margin: 30px 41px;
			}

			.h2 {
				width: 340px;
				min-height: 311px;
				background-color: #FFFFFF;
				margin-left: 10px;
				padding: 20px 10px;

				.lin {
					width: 320px;
					height: 1px;
					background-color: rgba(217, 217, 217, 1);
					margin: 10px 0;
				}
			}
		}
	}

	.c1 {
		color: #c0c0c0;
	}

	.c2 {
		color: #FFFFFF;
	}

	#SceneView {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.hd {
		width: 100%;
		height: 72px;
		background: linear-gradient(254.09deg, rgba(4, 44, 106, 0.75) -5.93%, rgba(4, 44, 106, 0.75) -5.92%, rgba(4, 44, 106, 0) 13.93%), linear-gradient(136.21deg, rgba(4, 44, 106, 0.75) -16.39%, rgba(4, 13, 33, 0.75) 16.62%, rgba(4, 13, 33, 0.75) 80.45%, rgba(72, 31, 73, 0.75) 119.01%);
		backdrop-filter: blur(3px);
		color: #FFFFFF;
		line-height: 72px;
		font-size: 20px;
		// display: flex;
		// justify-content: space-around;
		box-sizing: border-box;
		padding-left: 360px;
	}
</style>
