<template>
	<div style="height: 100%;">
		<el-container style="height: 100%;">
			<el-header style="height: 110px;padding: 0;">
				<div class="myhead">
					<div class="name" @click="Cknum(4)" style="cursor: pointer;">地震滑坡风险<br />分析与评估平台</div>
					<div class="item" @click="Cknum(0)">
						<span class="item-text" :class="ckNum == 0?'c2':'c1'">分析</span>
						<img class="item-icon" src="../assets/arrowd.png" />
					</div>
					<div class="item" @click="Cknum(1)">
						<span class="item-text" :class="ckNum == 1?'c2':'c1'">评估</span>
						<img class="item-icon" src="../assets/arrowd.png" />
					</div>
					<div class="item" @click="Cknum(2)">
						<span class="item-text" :class="ckNum == 2?'c2':'c1'">数据库</span>
						<img class="item-icon" src="../assets/arrowd.png" />
					</div>
					<!--     占位     -->
					<div style="width: 310px;height: 100px;"></div>
					<div v-if="phone == ''" class="login" @click="Cknum(3)">登录</div>
					<div v-if="phone !== ''" class="login">{{phone}}</div>
					<div style="color: #FFFFFF;font-size: 16px;line-height: 22px;">|</div>
					<!-- <div v-if="phone == ''" class="register" @click="Cknum(4)">注册</div> -->
					<img style="width: 32px;height: 32px;" src="../assets/share.png" />
					<img @click="open1()" style="width: 102px;height: 32px;margin-left: 10px;"
						src="../assets/langguage.png" />
				</div>
			</el-header>
			<el-main style="padding: 0;margin: 0;">
				<div class="mymain bg">
					<Home v-if="ckNum == 0"></Home>
					<Home2 v-if="ckNum == 1"></Home2>
					<Home1 v-if="ckNum == 2"></Home1>
					<Home0 @getNum="getNum" v-if="ckNum == 4"></Home0>
					<Login1  @getStudent="getStudentName" v-if="ckNum == 3"></Login1>
					<!-- <Login v-if="ckNum == 4"></Login> -->
				</div>
			</el-main>
		</el-container>

	</div>
</template>

<script>
	import {
		loadModules
	} from "esri-loader";
	import Home from './Home.vue'
	import Home1 from './Home1.vue'
	import Home0 from './Home0.vue'
	import Home2 from './Home2.vue'
	import Login from './login1.vue'
	import Login1 from './login2.vue'
	export default {
		name: "login",
		components: {
			Home,
			Home1,
			Login,
			Home2,
			Login1,
			Home0
		},
		data() {
			return {
				phone: '',
				code: '',
				password: '',
				repassword: '',
				maptr: true,
				map: null,
				ckNum: 3,
				Codeid: 0,

			}
		},
		mounted() {
			this.createView('92b6eaa87d454c0d88a5999d3e18bac6');
			// return
			setTimeout(() => {
				console.log('开始');
				// this.map.destroy()
				// this.map.clear()
				// this.createView('8435aa11ff2e48c7ad98778bb10fc51b');
			}, 7000)
		},
		methods: {
			open1() {
				const h = this.$createElement;

				this.$notify({
					title: '提示！',
					message: h('i', {
						style: 'color: teal'
					}, '板块建设中。。。')
				});
			},
			getStudentName(e) {
				console.log("父组件接受到Student的名字", e)
				this.Codeid = e.code
				if (e.code == 2000) {
					this.ckNum = 4
					this.phone = e.phone
				} else {

				}
			},
			getNum(e) {
				this.ckNum = e
			},
			Cknum(num) {
				console.log(this.Codeid, num);
				if (this.Codeid !== 2000) {
					this.ckNum = 3
					return
				}
				this.ckNum = num
				// console.log("切换中");
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				setTimeout(() => {
					loading.close();
				}, 500);
			},
			//创建视图
			createView(webmapid) {
				let _this = this
				//   let options = {
				//     url: "https://js.arcgis.com/4.15/",
				//     css: "https://js.arcgis.com/4.15/esri/themes/light/main.css"
				//   };

				//         const options = {
				//     url: 'https://js.arcgis.com/3.24/'
				// }
				const options = {
					// url: 'https://js.arcgis.com/3.24/',
					url: 'https://js.arcgis.com/3.24compact/',
					css: "https://js.arcgis.com/3.24/esri/css/esri.css"
				}
				//   loadModules([
				//     'esri/map',
				//     'esri/layers/ArcGISTiledMapServiceLayer',
				//     'esri/layers/ArcGISDynamicMapServiceLayer',
				//     'esri/symbols/SimpleMarkerSymbol',
				//     'esri/layers/GraphicsLayer',
				//     'esri/symbols/PictureMarkerSymbol',
				//     'esri/tasks/QueryTask',
				//     'esri/tasks/query',
				//     'esri/geometry/Point',
				//     'esri/graphic',
				//     'esri/SpatialReference',
				//     'esri/graphic',
				//   ],options).then(
				// "esri/views/SceneView", 
				loadModules(["esri/map", "esri/arcgis/utils", "dojo/domReady!"], options).then(
					([Map, SceneView]) => {
						// let map = new Map("SceneView1")
						// return
						var mapid = webmapid
						// var mapid = "f5e470d5a3d14167a8495b6c874ef6c3" // 添加onlinem
						var mapDeferred = esri.arcgis.utils.createMap(mapid, "SceneView1", {
							mapOptions: {
								slider: true,
								nav: false,
								// center: [118.538570, 34.3599187],
								zoom: 8,
								logo: false
							}
						}).then(function(response) {
							_this.map = response.map;
							// onMapLoaded(_this.map)
						});



						setTimeout(() => {
							// mapDeferred.clear()
							return
							console.log("开始切换");
							var mapid = "8435aa11ff2e48c7ad98778bb10fc51b"
							// var mapid = "f5e470d5a3d14167a8495b6c874ef6c3" // 添加onlinem
							var mapDeferred = esri.arcgis.utils.createMap(mapid, "SceneView", {
								mapOptions: {
									slider: true,
									nav: false,
									// center: [118.538570, 34.3599187],
									zoom: 8,
									logo: false
								}
							});
						}, 5000)

					}
				);
			},
			test() {
				alert('sss')
				console.log('uhh')
				uni.webView.navigateBack()
			},
		},
	}
</script>

<style scoped lang="scss">
	.c1 {
		color: #c0c0c0;
	}

	.c2 {
		color: #FFFFFF;
		// background-color: #000000;
	}

	.myhead {
		background: linear-gradient(136.21deg, rgba(4, 44, 106, 0.8) -16.39%, rgba(4, 13, 33, 0.8) 16.62%, rgba(4, 13, 33, 0.8) 80.45%, rgba(55, 31, 73, 0.8) 119.01%);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.name {
			width: 231px;
			height: 90px;
			font-weight: 400;
			font-size: 32px;
			line-height: 45px;
			display: flex;
			align-items: center;
			color: #FFFFFF;
		}

		.item {
			//min-width: 100px;
			height: 90px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			padding: 0 30px;

			&-text {
				margin-right: 7px;
				font-weight: 400;
				font-size: 16px;
				line-height: 22px;
				// padding: 40px 20px;
			}

			&-icon {
				width: 12px;
				height: 6px;
			}
		}

		.login {
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			color: #FFFFFF;
			margin-right: 20px;
			cursor: pointer;
		}

		.register {
			//min-width: 72px;
			cursor: pointer;
			height: 32px;
			line-height: 32px;
			border-radius: 60px;
			padding: 0 20px;
			color: #FFFFFF;
			border: 1px solid #FFFFFF;
			margin-left: 20px;
			margin-right: 10px;
		}
	}

	.mymain {
		width: 100%;
		height: 100%;
		position: relative;

		.loginbox {
			width: 1200px;
			height: 764px;
			background: linear-gradient(254.09deg, rgba(4, 44, 106, 0.75) -5.93%, rgba(4, 44, 106, 0.75) -5.92%, rgba(4, 44, 106, 0) 13.93%), linear-gradient(136.21deg, rgba(4, 44, 106, 0.75) -16.39%, rgba(4, 13, 33, 0.75) 16.62%, rgba(4, 13, 33, 0.75) 80.45%, rgba(72, 31, 73, 0.75) 119.01%);
			border-radius: 10px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			align-items: center;
			justify-content: center;

			.left {
				width: 392px;

				&-text {
					font-weight: 500;
					font-size: 40px;
					line-height: 56px;
					color: #FFFFFF;
					margin-bottom: 20px;
				}

				&-text2 {
					font-weight: 500;
					font-size: 24px;
					line-height: 34px;
					color: #E4E6EC;
					margin-bottom: 40px;
				}

				&-phone,
				.password,
				.repassword {
					width: 392px;
					height: 42px;
					margin-bottom: 16px;
				}

				&-yzm {
					width: 392px;
					height: 42px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 16px;
				}

				&-logintext {
					margin-top: 6px;
					width: 392px;
					font-weight: 500;
					font-size: 16px;
					line-height: 22px;
					text-align: right;
					color: #FFFFFF;
				}

			}

			.right {
				width: 502px;
				height: 502px;
				margin-left: 100px;

				&-img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.bg {
		// background-color: aqua;
		background: url('http://waterlogging.api.cqxddwl.com//image/2023/02/17/2023021709495821305048.png');
		background-size: 100vw;
		// background-image: url('../static/zc.png' no-repeat);
	}
</style>
