<template>
	<div style="margin-left: 20px;">
		<div class="icon1">
			<div class="ic1 px15">
			</div>
			<div>8-8+</div>
		</div>
		<div class="icon1">
			<div class="ic1 px12">
			</div>
			<div>7-7.9</div>
		</div>
		<div class="icon1">
			<div class="ic1 px10">
			</div>
			<div>6-6.9</div>
		</div>
		<div class="icon1">
			<div class="ic1 px7">
			</div>
			<div>5-5.9</div>
		</div>
		<div class="icon1">
			<div class="ic1 px5">
			</div>
			<div>4-4.9</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				map: null,
				ck: 0,
				count: 0,
				lst: true
			};
		},
		mounted() {},
		destroyed() {},
		methods: {}
	};
</script>

<style scoped lang="scss">
	.icon1 {
		display: flex;
		align-items: center;
		width: 70px;
		justify-content: space-between;

		.ic1 {
			background-color: rgba(222, 105, 64, 1);
			border-radius: 15px;
			margin-right: 10px;
		}
	}
	.px15{
		width: 15px;
		height: 15px;
	}
	.px12{
		width: 12px;
		height: 12px;
	}
	.px10{
		width: 10px;
		height: 10px;
	}
	.px7{
		width: 7px;
		height: 7px;
	}
	.px5{
		width: 5px;
		height: 5px;
	}
</style>
