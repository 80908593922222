<template>
	<div>
		<div class="loginbox" v-show="map_s">
			<div class="left">
				<div class="left-text">地震滑坡风险<br />分析与评估平台</div>
				<div class="left-text2">平台采用云计算技术，实现网络端操作，查找和分析区域滑坡危险性和风险评估结果</div>
				<div class="left-phone">
					<el-input v-model="phone" placeholder="请输入手机号"></el-input>
				</div>
				<div class="password">
					<el-input v-model="password" type="password" placeholder="请输入密码"></el-input>
				</div>
				<div class="left-btn">
					<el-button @click="test()" style="width: 392px;" type="primary">登录</el-button>
				</div>
				<!-- <div class="left-logintext">注册</div> -->
			</div>
			<div class="right" style="cursor: pointer;" @click="Ckmap">
				<img class="right-img" src="../static/Rectangle392.png" />
			</div>
		</div>
		<div class="loginbox" style="flex-wrap: wrap; overflow: hidden;" v-show="!map_s">
			<div style="width: 1200px; height: 52px;">
				<div style="width: 120px; height: 52px; line-height: 52px; font-size: 24px; background-color: aliceblue; border-radius: 10px; margin-left: 20px; text-align: center; cursor: pointer;" @click="Ckmap1">返回登录</div>
			</div>
			<div style="width: 1200px; height: 700px;" id="SceneView1">
				
			</div>
		</div>
	</div>
</template>

<script>
	import {
		loadModules
	} from "esri-loader";

	export default {
		data() {
			return {
				phone: '17706055311',
				code: '',
				password: 'ab123456',
				repassword: '',
				maptr: true,
				map: null,
				map_s:true
			};
		},
		mounted() {
			// this.createView('92b6eaa87d454c0d88a5999d3e18bac6');
		},
		destroyed() {
			// this.map.destroy()
		},
		methods: {
			Ckmap(){
				this.map_s = false
				this.createView('1bdf21b6239b46a7ab9ea4332d98cbdf');
			},
			Ckmap1(){
				this.map_s = true
				this.map.destroy()
			},
			//创建视图
			createView(webmapid) {
				let _this = this
				const options = {
					url: 'https://js.arcgis.com/3.24compact/',
					css: "https://js.arcgis.com/3.24/esri/css/esri.css"
				}
				loadModules(["esri/map", "esri/arcgis/utils", "dojo/domReady!"], options).then(
					([Map, SceneView]) => {
						var mapid = webmapid
						// var mapid = "f5e470d5a3d14167a8495b6c874ef6c3" // 添加onlinem
						var mapDeferred = esri.arcgis.utils.createMap(mapid, "SceneView1", {
							mapOptions: {
								slider: true,
								nav: false,
								// center: [118.538570, 34.3599187],
								zoom: 4,
								logo: false
							}
						}).then(function(response) {
							_this.map = response.map;
						});

					}
				);
			},
			test() {
				let obj = {
					"account": this.phone,
					"password": this.password
				}
				this.$newPost('/account/login', obj).then(res => {
					console.log(res);
					let obj1 = {
						code: res.code,
						phone: this.phone
					}
					if (res.code == 2000) {
						this.$emit("getStudent", obj1)
						this.$message({
							message: res.message,
							type: 'success'
						});
					} else {
						this.$message({
							message: res.message,
							type: 'warning'
						});
						this.$emit("getStudent", obj1)
					}
				})
				// uni.request({
				// 	url: 'http://landslide.api.zyyaq.com/account/login',
				// 	method: 'POST',
				// 	data: {
				// 		obj
				// 	},
				// 	success(res) {
				// 		console.log(res,'成功');
				// 	},
				// 	fail(res) {
				// 		console.log(res);
				// 	}
				// })
				// alert('sss')
				console.log('uhh')
				// this.$emit("getStudent", 'sss')
				// uni.webView.navigateBack()
			},
		}
	};
</script>

<style scoped lang="scss">
	.loginbox {
		width: 1200px;
		height: 764px;
		background: linear-gradient(254.09deg, rgba(4, 44, 106, 0.75) -5.93%, rgba(4, 44, 106, 0.75) -5.92%, rgba(4, 44, 106, 0) 13.93%), linear-gradient(136.21deg, rgba(4, 44, 106, 0.75) -16.39%, rgba(4, 13, 33, 0.75) 16.62%, rgba(4, 13, 33, 0.75) 80.45%, rgba(72, 31, 73, 0.75) 119.01%);
		border-radius: 10px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;

		.left {
			width: 392px;

			&-text {
				font-weight: 500;
				font-size: 40px;
				line-height: 56px;
				color: #FFFFFF;
				margin-bottom: 20px;
			}

			&-text2 {
				font-weight: 500;
				font-size: 24px;
				line-height: 34px;
				color: #E4E6EC;
				margin-bottom: 40px;
			}

			&-phone,
			.password,
			.repassword {
				width: 392px;
				height: 42px;
				margin-bottom: 16px;
			}

			&-yzm {
				width: 392px;
				height: 42px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 16px;
			}

			&-logintext {
				margin-top: 6px;
				width: 392px;
				font-weight: 500;
				font-size: 16px;
				line-height: 22px;
				text-align: right;
				color: #FFFFFF;
			}

		}

		.right {
			width: 502px;
			height: 502px;
			margin-left: 100px;

			&-img {
				width: 100%;
				height: 100%;
			}
		}
	}
</style>
